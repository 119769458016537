<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>
      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'" class="box_content">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <templateDivider title="下达预算"></templateDivider>

    <div class="small_title box_content">
      <div style="font-size: 0.175rem;">分解下达预算资金</div>
      <el-button v-if="type == 'add'" size="mini" type="primary" plain icon="el-icon-plus" @click="addCols"
        >增加</el-button
      >
    </div>

    <div class="box_content" style="height: 500px;">
      <el-table
        v-loading="loading"
        ref="table"
        :data="listData"
        style="width:100%;position: absolute;"
        height="500px"
        :header-cell-style="{ backgroundColor: '#F5F7FA' }"
      >
        <el-table-column fixed type="index" label="序号" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column fixed align="center" prop="countyId" width="140" label="行政区域">
          <template slot-scope="scope">
            <div>
              <el-cascader  :append-to-body="false"  
                v-model="scope.row.countyId"
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                :options="levalRegion"
                :props="treeProps"
                @change="handleChange($event, scope.$index)"
                :show-all-levels="false"
                placeholder="请选择"
              >
              </el-cascader>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="isPowerCounty" width="140" align="center" label="是否扩权（强）县">
          <template slot-scope="scope">
            <el-select
              v-if="type == 'add' || type == 'edit' || isView"
              size="mini"
              @change="handleChange1($event, scope.$index)"
              v-model="scope.row.isPowerCounty"
              placeholder="请选择"
            >
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>

            <div v-else>{{ scope.row.isPowerCounty ? '是' : '否' }}</div>
          </template>
        </el-table-column>

        <!-- 
          policyPapersId 省    cityPolicyPapersId 市 
          isView:false  不是扩强县
          isView:true   是扩强县    省数据
        -->
        <el-table-column v-if="!level2 && isView" prop="policyPapersId" width="350" align="center" label="文号">
          <template slot-scope="scope">
            <!-- :disabled="type == 'view' || type == 'charts'" -->
            <el-select
              v-if="type == 'add' || type == 'edit'"
              size="mini"
              @change="handleChange2($event, scope.$index)"
              v-model="scope.row.policyPapersId"
              placeholder="请选择省"
            >
              <el-option
                v-for="item in scope.row.documentNumberArr"
                :key="item.id"
                :label="item.documentNumber"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <span v-else>{{ scope.row.cityDocumentNumber ? scope.row.cityDocumentNumber : '--' }}</span>
          </template>
        </el-table-column>

        <el-table-column v-else prop="cityPolicyPapersId" width="660" align="center" label="文号">
          <template slot-scope="scope">
            <!-- :disabled="type == 'view' || type == 'charts'" -->
            <el-select
              v-if="type == 'add' || type == 'edit'"
              size="mini"
              @change="handleChange2($event, scope.$index)"
              v-model="scope.row.cityPolicyPapersId"
              placeholder="请选择市"
            >
              <el-option
                v-for="item in scope.row.documentNumberArr"
                :key="item.id"
                :label="item.documentNumber"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <span v-else>{{ scope.row.cityDocumentNumber ? scope.row.cityDocumentNumber : '--' }}</span>
          </template>
        </el-table-column>

        <el-table-column width="400" v-if="!level2 && isView" prop="documentName" align="center" label="文件名称">
          <template slot-scope="scope">
            {{ scope.row.documentName ? scope.row.documentName : '选择文号后回显' }}
          </template>
        </el-table-column>

        <el-table-column width="400" v-else prop="cityDocumentName" align="center" label="文件名称">
          <template slot-scope="scope">
            {{ scope.row.cityDocumentName ? scope.row.cityDocumentName : '选择文号后回显' }}
          </template>
        </el-table-column>

        <el-table-column prop="totalAmount" width="160" align="center" label="下达总金额（万元）">
          <template slot-scope="scope">
            {{ scope.row.totalAmount ? formatToTenThousandWithCustomCommas(scope.row.totalAmount) : '选择文号后回显' }}
          </template>
        </el-table-column>

        <el-table-column prop="budgetAmount" align="center" width="150" label="下达金额（万元）">
          <template slot-scope="scope">
            <el-input
              :disabled="type == 'view' || type == 'charts'"
              size="mini"
              v-model="scope.row.budgetAmount"
              placeholder="请输入"
              oninput="
                    this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                "
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="title1">
          <el-table-column align="center" width="160" prop="yearlyTotalPayments" label="累计支付总额">
            <template slot-scope="scope">
              <el-input
                size="mini"
                min="0"
                v-model="scope.row.yearlyTotalPayments"
                :disabled="type == 'view' || type == 'charts'"
                placeholder="请输入"
                oninput="
                    this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                "
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column align="center" width="160" prop="yearlyBookBalance" label="账面结存资金">
            <template slot-scope="scope">
              <el-input
                size="mini"
                min="0"
                v-model="scope.row.yearlyBookBalance"
                :disabled="type == 'view' || type == 'charts'"
                placeholder="请输入"
                oninput="
                    this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                "
              ></el-input>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column align="center" :label="title2">
          <el-table-column prop="nextYearQ1TotalPayments" width="160" align="center" label="累计支付总额">
            <template slot-scope="scope">
              <el-input
                size="mini"
                min="0"
                v-model="scope.row.nextYearQ1TotalPayments"
                :disabled="type == 'view' || type == 'charts'"
                placeholder="请输入"
                oninput="
                    this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                "
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="nextYearQ1BookBalance" width="160" align="center" label="账面结存资金">
            <template slot-scope="scope">
              <el-input
                size="mini"
                min="0"
                v-model="scope.row.nextYearQ1BookBalance"
                :disabled="type == 'view' || type == 'charts'"
                placeholder="请输入"
                oninput="
                    this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                "
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="nextYearQ1ReclaimedByFinance" width="160" align="center" label="被财政收回资金">
            <template slot-scope="scope">
              <el-input
                size="mini"
                min="0"
                v-model="scope.row.nextYearQ1ReclaimedByFinance"
                :disabled="type == 'view' || type == 'charts'"
                placeholder="请输入"
                oninput="
                    this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                "
              ></el-input>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column fixed="right" v-if="type == 'add'" align="center" width="80" prop="name" label="操作">
          <template slot-scope="scope">
            <el-link type="danger" @click="removeCol(scope.$index, scope.row)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="import_opration" :disabled="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'

import { _getLevelRegion } from '@/api/keepHome'

import {
  _BudgetExpenditureAdd,
  _BudgetExpenditureInfo,
  _BudgetExpenditureEdit,
  _BudgetExpenditurePolicyPaperSelect
} from '@/api/modular/postImmigrationService/monitorAndTheUse/useOfFunds.js'

export default {
  data() {
    return {
      type: '',
      loading: false,
      row: {},
      title: '',
      input: '',
      listData: [],
      title1: '',
      title2: '',
      searchObj: {
        year: ''
      },
      form: {},
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }],
        isPowerCounty: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      levalRegion: [], //成都市
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      // treeProps: { label: 'name', value: 'id', checkStrictly: true },
      newarr: ['否', '是'],
      options: [
        { label: '是', value: true },
        { label: '否', value: false }
      ],
      documentNumberArr: [], //文号列表
      flag: null,
      pid: null,
      pid1: null,
      id1: null,
      level2: null,
      isView: false,
      bufferList: [{}]
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    handleChange(value, index) {
      let obj = this.getPids(value)
      console.log(obj, '====obj===')
      this.listData[index].documentNumberArr = []
      this.listData[index].policyPapersId = null
      this.listData[index].cityPolicyPapersId = null

      this.listData[index].documentName = ''
      this.listData[index].cityDocumentName = ''
      this.listData[index].totalAmount = 0

      if (obj.level == 2 && obj.name != '省本级') {
        // 市是市
        console.log("obj.level == 2 && obj.name != '省本级'")
        this.pid = obj.id

        this.listData[index].isView = true
        this.listData[index].level2 = true

        // this.isView = true
        // this.level2 = true
        this.listData[index].isPowerCounty = false
        this.getdocumentNumberList(this.pid, index)
      } else if (obj.level == 2 && obj.name == '省本级') {
        // 省本级是省
        console.log("obj.level == 2 && obj.name == '省本级'")

        this.listData[index].isView = true
        this.listData[index].level2 = false

        // this.isView = true
        // this.level2 = false

        this.pid = obj.pid
        this.listData[index].isPowerCounty = false
        this.getdocumentNumberList(this.pid, index)
      } else {
        let pids = obj.pids.split(',')
        console.log(pids, '=======pids=========')
        this.pid1 = Number(pids[1])
        this.id1 = Number(pids[2])
        this.listData[index].isPowerCounty = null
        this.listData[index].isView = false
        this.listData[index].level2 = true

        // this.isView = false
        // this.level2 = true
      }
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
      this.listData[index].orderNo = obj.orderNo
    },
    handleChange1(value, index) {
      this.listData[index].documentNumberArr = []
      this.listData[index].policyPapersId = null
      this.listData[index].cityPolicyPapersId = null

      this.listData[index].documentName = ''
      this.listData[index].cityDocumentName = ''
      this.listData[index].totalAmount = 0

      console.log(value, '=======handleChange1=======')
      if (value) {
        //是  省
        this.getdocumentNumberList(this.pid1, index)
      } else {
        this.getdocumentNumberList(this.id1, index)
      }
    },
    handleChange2(value, index) {
      let obj = this.listData[index].documentNumberArr.find(ele => {
        return ele.id == value
      })

      this.listData[index].documentName = obj?.documentName
      this.listData[index].documentName = obj?.documentName
      this.listData[index].cityDocumentName = obj?.documentName
      this.listData[index].totalAmount = obj?.totalAmount
      this.listData[index].policyPapersId =
        obj?.parentPolicyPapers?.id == undefined ? value : obj?.parentPolicyPapers?.id

      console.log(obj, '======documentNumberArr========', this.listData[index].policyPapersId)
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    refreshYear(year) {
      this.searchObj.year = year
      this.title1 = `截至${this.searchObj.year}年12月31日资金支付情况（万元）`
      this.title2 = `截至${this.searchObj.year + 1}年3月31日资金支付情况（万元）`
    },
    async add(type) {
      this.type = type
      this.title = '新增'
    },
    async view(type, row) {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })

      this.type = type
      this.row = row
      this.searchObj.year = row.year

      this.title1 = `截至${this.row.year}年12月31日资金支付情况（万元）`
      this.title2 = `截至${this.row.year + 1}年3月31日资金支付情况（万元）`

      this.title = type == 'view' ? '详情' : '查看统计表'

      let PidsArr = row.countyPids.split(',')
      let Pidslen = row.countyPids.replace(/,/, ',').split(',').length - 1

      if (Pidslen == 2 && row.county == '省本级') {
        // this.listData[0].isView = true
        // this.listData[0].level2 = false

        // this.isView = true
        // this.level2 = false

        let res = await this.getdocumentNumberList(Number(PidsArr[1]))
        console.log(res, '111')
        this.getBasicInfo()
      } else if (Pidslen == 2 && row.county != '省本级') {
        this.isView = true
        this.level2 = true

        // this.listData[0].isView = true
        // this.listData[0].level2 = true

        let res = await this.getdocumentNumberList(row.countyId)
        console.log(res, '222')
        this.getBasicInfo()
      } else {
        this.level2 = true
        if (row.isPowerCounty) {
          let res = await this.getdocumentNumberList(Number(PidsArr[1]))
          console.log(res, '333')
          this.getBasicInfo()
        } else {
          let res = await this.getdocumentNumberList(Number(PidsArr[2]))
          console.log(res, '444')
          this.getBasicInfo()
        }
      }
    },
    editCols() {
      this.type = 'edit'
      this.title = '编辑'

      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    getdocumentNumberList(value, index) {
      let params = {
        year: this.searchObj.year,
        id: value
      }
      _BudgetExpenditurePolicyPaperSelect(params).then(res => {
        if (res.code == 200) {
          if (this.type == 'view') {
            console.log(res.data)
            this.documentNumberArr = res.data

            // this.bufferList[0].documentNumberArr = this.documentNumberArr

            // this.listData = this.bufferList

            // console.log(this.listData, 33333)
          } else {
            this.listData[index].documentNumberArr = res.data
          }
        }
      })
    },
    btnClose() {
      this.bufferList = [{}]
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: null, //县（市区）
            county: '', //县（市区）
            countyPids: '', //pids
            orderNo: null,
            isPowerCounty: null,
            policyPapersId: null,
            cityPolicyPapersId: null,
            budgetAmount: null,
            yearlyTotalPayments: null,
            yearlyBookBalance: null,
            nextYearQ1TotalPayments: null,
            nextYearQ1BookBalance: null,
            nextYearQ1ReclaimedByFinance: null,
            documentNumberArr: []
          }
          this.listData.splice(this.listData.length, 0, obj)
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    refreshTable() {
      this.getBasicInfo()
    },

    submitForm() {
      let listData = this.listData.map(ele => {
        return {
          ...ele,
          budgetAmount: Number(ele.budgetAmount),
          stabilityPlanFalse: ele.stabilityPlanTrue == 0 ? 1 : 0
        }
      })
      console.log(listData, '===submitForm===')
      let flag = listData.every(ele => ele.countyId)

      switch (this.type) {
        case 'add':
          if (flag) {
            _BudgetExpenditureAdd(listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
        case 'edit':
          let ele = listData[0]
          let params = {
            year: ele.year,
            countyId: ele.countyId,
            county: ele.county,
            countyPids: ele.countyPids,
            orderNo: ele.orderNo,
            isPowerCounty: ele.isPowerCounty,
            policyPapersId: ele.policyPapersId,
            cityPolicyPapersId: ele.cityPolicyPapersId,
            budgetAmount: Number(ele.budgetAmount),
            yearlyTotalPayments: Number(ele.yearlyTotalPayments),
            yearlyBookBalance: Number(ele.yearlyBookBalance),
            nextYearQ1TotalPayments: Number(ele.nextYearQ1TotalPayments),
            nextYearQ1BookBalance: Number(ele.nextYearQ1BookBalance),
            nextYearQ1ReclaimedByFinance: Number(ele.nextYearQ1ReclaimedByFinance),
            id: ele.id
          }
          if (flag) {
            _BudgetExpenditureEdit(params).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
      }
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }

      _BudgetExpenditureInfo(params).then(res => {
        if (res.code == 200) {
          console.log(res.data, '=========res.data==========', this.documentNumberArr)

          let newarr = [res.data].map(ele => {
            return {
              ...ele,
              documentNumberArr: this.documentNumberArr
            }
          })

          this.listData = newarr
          // this.bufferList = newarr
          console.log(this.bufferList, '==============this.bufferList==============')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';

::v-deep tbody tr.el-table__row:nth-child(1) {
  height: 49px;
  border: 0 !important;
  background-color: #fff !important;
  position: relative !important;
  top: 0;
  left: 0;
  z-index: 1;
  td {
    border-top: 0 !important;
    border-bottom: 1px solid #f5f7fa !important;
  }
  td:first-child {
    border-left: 0 !important;
  }
  td:last-child {
    border-right: 0 !important;
  }
}
</style>
